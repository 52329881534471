<template>
     <PreLoadingOverlay />
     <HeaderAfterLogin v-if="token" />
     <HeaderGuest v-else />
     <router-view />
     <Footer />
</template>

<script>
import HeaderGuest from "components/navigation/guest/index.vue";
import HeaderAfterLogin from "components/navigation/afterlogin/index.vue";
import Footer from "components/footer/index.vue";
import { computed, onMounted, watch } from "vue";
import { authStore } from "store/auth.js";
import eventBus from "plugins/event.js";
import _ from "underscore";
import Cookies from "js-cookie";
import { useRoute } from "vue-router";
import PreLoadingOverlay from "components/loading/preLoadingOverlay.vue";
import { ref } from "vue";
import { utilitiesStore } from "store/utilities.js";
import { bonusStore } from "store/bonus.js";

export default {
     components: {
          HeaderGuest,
          HeaderAfterLogin,
          Footer,
          PreLoadingOverlay,
     },
     setup() {
          const route = useRoute();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useBonusStore = bonusStore();

          const token = computed(() => {
               return useAuthStore.token;
          });

          const user = computed(() => {
               return useAuthStore.user;
          });

          const loading = computed(() => {
               return useUtilitiesStore.loading;
          });

          watch(user, (newVal) => {
               if (newVal?.meta) {
                    if (route.name == "home") {
                         if (route.query.modal == "dailybonus") {
                              return;
                         }

                         let filter = _.filter(newVal.meta, function (params) {
                              return params["meta_key"] == "welcome_bonus";
                         });

                         if (filter.length == 0) {
                              setTimeout(async () => {
                                   await useBonusStore.getWelcome();
                              }, 0)
                              setTimeout(() => {
                                   if (Cookies.get("welcome") == null) {
                                        var currentTime = new Date();
                                        eventBus.emit("open:welcomebonus");
                                        // Cookies.set("welcome", true, {
                                        //      expires: new Date(
                                        //           currentTime.getTime() +
                                        //                60 * 60 * 1000
                                        //      ),
                                        // });
                                   }
                              }, 3000);
                         }
                    }

                    let filter = _.filter(newVal.meta, function (params) {
                         return params["meta_key"] == "free_gc_topup";
                    });

                    if (filter.length >= 1) {
                         if (!loading) {
                              useBonusStore.claimFreeGC()
                         }
                    }
               }
          });

          watch(loading, (newVal) => {
               if(!newVal) {
                    if (user?.value?.meta) {
                         let filter = _.filter(user.value.meta, function (params) {
                              return params["meta_key"] == "free_gc_topup";
                         });


                         if (filter.length >= 1) {
                              useBonusStore.claimFreeGC()
                         }
                    }
               }
          });

          onMounted(() => {
               if(!loading.value) {
                    return;
               }
               useUtilitiesStore.preLoading(true);
               useUtilitiesStore.disableScroll();
               setTimeout(() => {
                    useUtilitiesStore.preLoading(false);
                    useUtilitiesStore.enableScroll();
               }, 3000);
          });

          return {
               token,
          };
     },
};
</script>
