<template>
  <div class="nav-container font-roboto tracking-wider font-bold text-base xl:text-lg">
    <nav class="fixed top-0 z-40 w-full bg-background-3">
      <div class="flex-auto header-bg w-full flex flex-wrap flex-row-reverse lg:flex-row items-center justify-between mx-auto gap-2 bg-background-3">
        <button @click="menuOpen = true" class="hidden lg:block flex-initial flex-wrap items-center justify-between mx-auto p-4 text-white hover:text-primal-yellow font-lg rounded-lg text-sm pl-5 py-2.5" type="button">
            <img
                class="w-8 xs:w-auto"
                width="32"
                height="32"
                :src="baseUrl.img+'/assets/img/header/burgermenu.svg'"
                alt=""
            >
        </button>

        <div class="flex-1 header-bg w-full flex flex-wrap items-center justify-between mx-auto  pl-2 xs:pl-4 sm:pl-8 md:pl-8 lg:pl-0 lg:px-2 pr-4 xs:pr-4 sm:pr-8 md:pr-8 lg:pr-12 2xl:pr-24">
            <router-link :to="{ name: 'home' }" class="flex items-center space-x-3 rtl:space-x-reverse h-22">
                <img
                    width="96"
                    height="60"
                    class="w-24 -translate-y-1 lg:w-30 lg:translate-y-0 "
                    :src="baseUrl.img+'/assets/img/logo.webp'"
                    alt=""
                >
            </router-link>
            <div class="flex items-center space-x-4 xl:space-x-8">
                <div class="hidden lg:block items-center justify-between w-full lg:flex md:w-auto" id="navbar-cta">
                    <div class="flex items-center p-4 md:p-0 mt-4 space-x-10 xl:space-x-16 flex-row md:mt-0 md:border-0">
                        <router-link :to="{ name: 'home' }">
                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('home') || isUrl('') ? 'text-secondary-3' : 'hover:text-secondary-3 text-shade-1'">Home</div>
                        </router-link>
                        <router-link :to="{ name: 'games' }">
                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('games') ? 'text-secondary-3' : 'hover:text-secondary-3 text-shade-1'">Games</div>
                        </router-link>
                        <router-link :to="{ name: 'promotion' }">
                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('promotion') ? 'text-secondary-3' : 'hover:text-secondary-3 text-shade-1'">Promotions</div>
                        </router-link>
                        <router-link :to="{ name: 'support' }">
                            <div class="transform ease-in-out transition-all duration-200" :class="isUrl('support') ? 'text-secondary-3' : 'hover:text-secondary-3 text-shade-1'">Support</div>
                        </router-link>
                    </div>
                </div>
                <div class="hidden lg:flex flex-row space-x-3 tracking-widest">
                    <router-link :to="{ name: 'login' }">
                        <button type="button" class="text-secondary-3 bg-background-4 px-8 xs:px-10 xl:px-12 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">Log In</button>
                    </router-link>
                    <router-link :to="{ name: 'register' }">
                        <button type="button" class="text-primary-1 bg-secondary-3 px-8 xs:px-10 xl:px-12 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">Sign Up</button>
                    </router-link>
                </div>
                <div class="lg:hidden flex flex-row space-x-1 tracking-widest">
                    <router-link :to="{ name: 'login' }" class="w-28 md:w-32">
                        <button type="button" class="text-secondary-3 bg-background-4 px-6 xs:px-8 xl:px-12 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">Log In</button>
                    </router-link>
                </div>
                <button @click="menuOpen = true" class="lg:hidden flex flex-wrap items-center justify-between mx-auto text-white hover:text-primal-yellow transform ease-in-out transition-all duration-200" type="button">
                    <img
                        width="32"
                        height="32"
                        class="w-8 h-8 md:w-auto"
                        :src="baseUrl.img+'/assets/img/header/burgermenu.svg'"
                        alt=""
                    >
                </button>
            </div>
        </div>
      </div>
    </nav>

    <!-- drawer component -->
    <div :style="!menuOpen ? 'left:-256px' : ''" id="drawer-navigation" class="fixed top-0 left-0 z-40 w-64 h-full pb-4 overflow-y-auto transform bg-background-1 text-shade-1 ease-in-out transition-all duration-300" tabindex="-1" aria-labelledby="drawer-navigation-label">
        <div class="bg-background-2">
            <div class="px-8 pb-4">
                <div class="pt-2 pb-12">
                    <router-link @click="menuOpen = false" :to="{ name: 'home' }">
                        <img
                            class="mx-auto"
                            :src="baseUrl.img+'/assets/img/logo.webp'"
                            style="width: 123px; height: 72px;"
                            alt=""
                        >
                    </router-link>
                </div>
                <div class="flex justify-start items-center space-x-2">
                    <div class="rounded-full h-11 w-11 bg-shade-4 content-center">
                        <img
                            class="w-auto mx-auto px-1"
                            :src="baseUrl.img+'/assets/img/logo.svg'"
                            alt=""
                        >
                    </div>
                    <div class="flex justify-center flex-col items-center">
                        <p class="text-sm leading-5 font-bold text-white">Guest</p>
                    </div>
                </div>
            </div>
        </div>
        <button  @click="menuOpen = false" type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation" class="bg-transparent rounded-lg text-sm p-1.5 absolute top-2 end-2 inline-flex items-center" >
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close menu</span>
        </button>
        <div class="flex flex-col items-start left-nav-height">
            <div class="flex-1 pt-8 overflow-y-auto w-full custom-scrollbar">
                <ul class="left-navigation-container space-y-2 font-medium px-8">
                    <li>
                    <router-link :to="{ name: 'games' }" class="flex items-center py-2 rounded-lg group" :class="isUrl('games') ? 'active-left-navigation' : ''" @click="menuOpen = false" >
                        <div class="h-8 w-8 content-center">
                            <img
                                :src="baseUrl.img+'/assets/img/header/games.svg'"
                            >
                        </div>
                        <span class="ms-3 font-bold">All games</span>
                    </router-link>
                    </li>
                    <li>
                    <router-link @click="menuOpen = false" :to="{ name: 'promotion' }" class="flex items-center py-2 rounded-lg group" :class="isUrl('promotion') ? 'active-left-navigation' : ''">
                        <div class="h-8 w-8 content-center">
                            <img
                                :src="baseUrl.img+'/assets/img/header/promotion.svg'"
                            >
                        </div>
                        <span class="ms-3 font-bold">Promotions</span>

                    </router-link>
                    </li>
                    <li>
                    <router-link @click="menuOpen = false" :to="{ name: 'support' }" class="flex items-center py-2 rounded-lg group" :class="isUrl('support') ? 'active-left-navigation' : ''">
                        <div class="h-8 w-8 content-center">
                            <img
                                :src="baseUrl.img+'/assets/img/header/support.svg'"
                            >
                        </div>
                        <span class="ms-3 font-bold">Support</span>
                    </router-link>
                    </li>
                </ul>
            </div>
            <div class="py-5 w-64 z-40 px-10 text-white cursor-pointer transform ease-in-out transition-all duration-300 text-base">
                <div class="flex flex-col items-center justify center gap-2 tracking-widest">
                    <router-link :to="{ name: 'register' }" class="flex-1 w-full">
                        <button type="button" class="text-primary-1 bg-secondary-3 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">Sign Up</button>
                    </router-link>
                    <router-link :to="{ name: 'login' }" class="flex-1 w-full">
                        <button type="button" class="text-secondary-3 bg-background-4 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">Log In</button>
                    </router-link>
                </div>
            </div>

        </div>
    </div>

    <div v-if="menuOpen" @click="menuOpen = false" class="fixed inset-0 z-30" style="
    background-color: rgba(0,0,0,.3);
    "></div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3'
import { useRoute } from 'vue-router'
import { baseUrl } from "config/services.js";

export default {
  components: {
    Link,
  },
  setup(){
    const menuOpen = ref(false);
    const sidebarOpen = ref(false);
    const route = useRoute();

    const isUrl = (...urls) => {
      let currentUrl = route.path.substr(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    };

    return {
      sidebarOpen,
      menuOpen,
      isUrl,
      baseUrl
    }
  }
};
</script>
