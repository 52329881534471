<template>
    <PreLoadingOverlay />
    <HeaderPlay />
    <router-view />
</template>

<script>
import HeaderPlay from "components/navigation/afterlogin/play.vue";
import _ from "underscore";
import PreLoadingOverlay from "components/loading/preLoadingOverlay.vue";

export default {
    components: {
         HeaderPlay,
         PreLoadingOverlay,
    },
    setup() {

         return {};
    },
};
</script>
