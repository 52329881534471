<template>
     <div
          role="status"
          class="fixed -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 w-full h-full flex items-center justify-center transition-all duration-700"
          :class="loading ? 'opacity-100 z-100' : 'opacity-0 -z-100'"
     >
          <div class="bg-background-3 w-full h-full absolute flex justify-center items-center">
               <img
                    width="123"
                    height="72"
                    loading="lazy"
                    class="transition-transform duration-300"
                    style="width: 123px; height: 72px;"
                    :src="baseUrl.img+'/assets/img/logo.webp'"
                    alt=""
               >
          </div>
     </div>
</template>

 <script>
import { utilitiesStore } from "store/utilities.js";
import { computed } from "vue";
import { baseUrl } from "config/services.js";

export default {
     setup() {
          const useUtilitiesStore = utilitiesStore();

          const loading = computed(() => {
               return useUtilitiesStore.loading;
          });

          return {
               loading,
               baseUrl
          };
     },
};
</script>
