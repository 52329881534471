<template>
     <div class="space-y-3">
          <div>
               <label
                    for="email"
                    class="block mb-1 text-sm text-shade-11 font-normal"
               >Email Address *</label>
               <input
                    v-model="profileFormModel.email"
                    type="email"
                    name="email"
                    id="email"
                    class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                    placeholder="Enter your email"
                    autocomplete="username"
                    readonly
                    @change="clearFormError('email')"
                    :class="profile_form.errors?.errors?.email != null || !isUser?.email_verified_at ? 'border-primal-red focus:ring-primal-red focus:border-primal-red' : 'border-shade-10 focus:ring-background-2 focus:border-background-2'"
               >
               <has-error-form v-model:form="profile_form" :field="'profile_form'"></has-error-form>
               <transition
                    enter-from-class="opacity-0 -translate-y-1.5"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-out duration-300"
                    leave-to-class="opacity-0 -translate-y-1.5"
                >
               <div
                    v-if="!isUser?.email_verified_at"
                    class="mt-1 text-sm text-red-600 text-xs text-primal-red ease-in-out transition-all duration-300"
               >Your email is not yet verified. <span
                         class="text-xs cursor-pointer text-primal-red underline italic uppercase"
                         @click="resendVerification()"
                    >Resend Verification</span></div>
                    </transition>
          </div>

          <div class="flex items-start gap-3">
               <div class="flex-1">
                    <label
                         for="first_name"
                         class="block mb-1 text-sm text-shade-11 font-normal"
                    >First name *</label>
                    <input
                         v-model="profileFormModel.first_name"
                         type="text"
                         name="first_name"
                         id="first_name"
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="Enter your first name"
                         @change="clearFormError('first_name')"
                         :class="profile_form.errors?.errors?.first_name != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red' : 'border-shade-10 focus:ring-background-2 focus:border-background-2'"
                    >
                    <has-error-form v-model:form="profile_form" :field="'first_name'"></has-error-form>
               </div>
               <div class="flex-1">
                    <label
                         for="last_name"
                         class="block mb-1 text-sm text-shade-11 font-normal"
                    >Last name *</label>
                    <input
                         v-model="profileFormModel.last_name"
                         type="text"
                         name="last_name"
                         id="last_name"
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="Enter your last name"
                         @change="clearFormError('last_name')"
                         :class="profile_form.errors?.errors?.last_name != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red' : 'border-shade-10 focus:ring-background-2 focus:border-background-2'"
                    >
                    <has-error-form v-model:form="profile_form" :field="'last_name'"></has-error-form>
               </div>
          </div>

          <div class="flex-1">
               <label
                    for="phone"
                    class="block mb-1 text-sm text-shade-11 font-normal"
               >Phone number *</label>
               <div class="reg-phone-c" :class="profile_form.errors?.errors?.phone != null || (!isValidPhoneNumber && isValidPhoneNumber != null) ? 'error' : ''">
                    <!-- v-model="set_phone_form" -->
                    <!-- @change="clearFormError('phone')" -->
                    <!-- :autoDefaultCountry="false" -->
                    <!-- :dropdownOptions="{
                              showDialCodeInList: true,
                              showDialCodeInSelection: true,
                              showFlags: true,
                              showSearchBox: true,
                         }"
                         :inputOptions="{
                              showDialCode: true,
                              maxlength: 25
                         }" -->
                    <vue-tel-input 
                         v-model="set_phone_form" 
                         mode="international" 
                         :autoFormat="false"
                         :defaultCountry="'US'"
                         :dropdownOptions="{
                              showDialCodeInList: true,
                              showDialCodeInSelection: true,
                              showFlags: true,
                              showSearchBox: true,
                         }"
                         @validate="onValidatePhone"
                         @on-input="handlePhoneNumberInput"
                         @country-changed="onCountryChanged"
                    ></vue-tel-input>
                    <!-- <vue-tel-input
                         v-model="set_phone_form"
                         :defaultCountry="'US'"
                         :autoDefaultCountry="false"
                         :autoFormat="false"
                         @on-input="handlePhoneNumberInput"
                         @validate="onValidatePhone"
                         @country-changed="onCountryChanged"
                         mode="international"
                         
                    ></vue-tel-input> -->
               </div>
               <has-error-form v-model:form="profile_form" :field="'phone'"></has-error-form>
               <transition
                    enter-from-class="opacity-0 -translate-y-1.5"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-out duration-300"
                    leave-to-class="opacity-0 -translate-y-1.5"
                >
               <div v-if="profile_form.errors?.errors?.phone == null && !isValidPhoneNumber && isValidPhoneNumber != null" class="mt-1 text-sm text-red-600 text-xs text-primal-red ease-in-out transition-all duration-300">Invalid Phone number</div>
            </transition>
          </div>

          <div class="flex-1">
               <label
                    for="address_1"
                    class="block mb-1 text-sm text-shade-11 font-normal"
               >Address line *</label>
               <input
                    v-model="profileFormModel.address.address_1"
                    type="text"
                    name="address_1"
                    id="address_1"
                    class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                    placeholder="Enter your address"
                    @change="clearFormError('address_1')"
                    :class="profile_form.errors?.errors?.address_1 != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red' : 'border-shade-10 focus:ring-background-2 focus:border-background-2'"
               >
               <has-error-form v-model:form="profile_form" :field="'address_1'"></has-error-form>
          </div>

          <div class="flex items-start gap-3">
               <div class="flex-1">
                    <label
                         for="province_id"
                         class="block mb-1 text-sm text-shade-11 font-normal"
                    >State *</label>
                    <searchableSelect :form="profileFormModel" :options="state" v-model:selectedOption="profileFormModel.address.province_id" :optionLabel="'province_name'" :optionValue="'id'" :placeholder="'Select state'"/>
                    <has-error-form v-model:form="profile_form" :field="'province_id'"></has-error-form>
               </div>
               <div class="flex-1">
                    <label
                         for="city_id"
                         class="block mb-1 text-sm text-shade-11 font-normal"
                    >City *</label>
                    <searchableSelect :form="profileFormModel" :options="city" v-model:selectedOption="profileFormModel.address.city_id" :optionLabel="'city_name'" :optionValue="'city_name'" :placeholder="'Select city'"/>
                    <has-error-form v-model:form="profile_form" :field="'city_id'"></has-error-form>
               </div>
               <div class="flex-1">
                    <label
                         for="zip_code"
                         class="block mb-1 text-sm text-shade-11 font-normal"
                    >Zip code *</label>
                    <searchableSelect :form="profileFormModel" :options="zipCode" v-model:selectedOption="profileFormModel.address.zip_code" :optionLabel="'zip_code'" :optionValue="'zip_code'" :placeholder="'Zip Code'"/>
                    <has-error-form v-model:form="profile_form" :field="'zip_code'"></has-error-form>
               </div>
          </div>

          <div class="flex-1">
               <label
                    for="birthday"
                    class="block mb-1 text-sm text-shade-11 font-normal"
               >Date of birth *</label>
               <input
                    v-model="profileFormModel.birthday"
                    type="date"
                    name="birthday"
                    id="birthday"
                    class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                    placeholder="MM/DD/YYY"
                    :max="eighteenYearsAgo()"
                    @change="clearFormError('birthday')"
                    :class="profile_form.errors?.errors?.birthday != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red' : 'border-shade-10 focus:ring-background-2 focus:border-background-2'"
               >
               <has-error-form v-model:form="profile_form" :field="'birthday'"></has-error-form>
          </div>
     </div>
</template>

<script>
import { onMounted, ref, reactive, computed, watch } from "vue";
import { useFormatter } from "composables/use-formatter.js";
import Form from "vform";
import { authStore } from "@/store/auth";
import { utilitiesStore } from "store/utilities.js";
import {
     getCity as getCityAPI,
     getZipCode as getZipCodeAPI,
} from "api/utility.js";
import _ from "underscore";
import HasErrorForm from "components/haserror/index.vue";
import searchableSelect from "components/searchableselect/index.vue";

export default {
     props: {
          isUser: {
               type: Object,
               required: true,
          },
          loading: {
               type: Boolean,
               required: true,
          },
     },
     components: {
        HasErrorForm,
        searchableSelect
    },
     setup(props, { emit }) {
          
          const { eighteenYearsAgo } = useFormatter();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();

          const city = ref([]);
          const zipCode = ref([]);

          const stateVal = ref(null);
          const cityVal = ref(null);

          const isValidPhoneNumber = ref(null);

          const emailVerificationResendForm = reactive(
               new Form({
                    email: props.isUser?.email ? props.isUser.email : "",
               })
          );

          const set_phone_form = computed(() => {
               return useAuthStore.set_phone_form;
          });

          // const set_phone_form = computed({
          //      get() {
          //           // if(useAuthStore.set_phone_form && selected_country.value) {
          //           //      if (useAuthStore.set_phone_form.startsWith("+"+selected_country.value.dialCode)) {
          //           //      // Remove the dial code prefix on first load
          //           //           const formattedPhoneNumber = useAuthStore.set_phone_form.replace("+"+selected_country.value.dialCode, "");
          //           //           useAuthStore.updatePhoneFormValue(formattedPhoneNumber);
          //           //           return formattedPhoneNumber;
          //           //      } else {
          //           //           return useAuthStore.set_phone_form;
          //           //      }
          //           // }
          //           return useAuthStore.set_phone_form;
          //      },
          //      // setter
          //      set(newValue) {
          //           useAuthStore.updatePhoneFormValue(newValue);
          //      }
          // });

          const set_phone_area = computed({
               get() {
                    return useAuthStore.set_phone_area;
               },
               // setter
               set(newValue) {
                    useAuthStore.updatePhoneAreaValue(newValue);
               }
          });

          // const selected_country = computed({
          //      get() {
          //           return useAuthStore.selected_country;
          //      },
          //      // setter
          //      set(newValue) {
          //           useAuthStore.updateSelectedCountry(newValue);
          //      }
          // });

          const profileFormModel = computed({
               get: () => useAuthStore.profile_form,
                 set: (value) => {
                   console.log(value);
                 },
          });

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const profile_form = computed(() => {
               return useAuthStore.profile_form;
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const resendVerification = () => {
               emit("update:loading", true);
               useAuthStore
                    .emailVerificationResend(emailVerificationResendForm)
                    .then(() => {
                         emit("update:loading", false);
                    })
                    .catch((e) => {
                         emit("update:loading", false);
                    });
          };

          async function getCity(id) {
               await getCityAPI({ province_id: id }).then((res) => {
                    city.value = res;
               });
          };

          async function getZipCode(id, cityName) {
               const filter = _.filter(city.value, function (params) {
                    return params["city_name"] == cityName;
               });

               if (filter[0]?.county) {
                    const params = {
                         city_name: cityName,
                         county: filter[0]?.county,
                         province_id: id,
                    };

                    await getZipCodeAPI(params).then((res) => {
                         zipCode.value = res;
                    });
               }
          };

          const NumbersOnly = (e) => {
               if (e.key === '.')
               return e.preventDefault();
               if (e.key === 'e')
               return e.preventDefault();

               e = (e) ? e : window.event;
               var charCode = (e.which) ? e.which : e.keyCode;
               if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
               e.preventDefault();;
               } else {
               return true;
               }
          };

          const handlePhoneNumberInput = (newPhoneNumber) => {
               useAuthStore.updatePhoneFormValue(newPhoneNumber);
          };

          const onValidatePhone = (value) => {
               isValidPhoneNumber.value = value.valid;
          };

          const onCountryChanged = (value) => {
               useAuthStore.updateSelectedCountry(value);
               useAuthStore.updatePhoneAreaValue(value.iso2);
               useAuthStore.updateProfileFormValue('phone_area', value.iso2);
          };

          const clearFormError = (key) => {
            useAuthStore.clearCurrentFormError(key);
          };

          watch(error_msg, (newVal) => {
               if (newVal) {
                    useAuthStore.updateProfileFormValue("errors", newVal);
               }
          });

          watch(useAuthStore.profile_form, async (newVal) => {
                stateVal.value = newVal.address.province_id;
                cityVal.value = newVal.address.city_id;
                // getZipCode(newVal.address.province_id, newVal.address.city_id);
          });

          watch(stateVal, async (newVal) => {
            if(newVal) {
                getCity(newVal);
                useAuthStore.updateAddressCity('');
                zipCode.value = [];
                useAuthStore.updateAddressZipcode('');
            } else {
                useAuthStore.updateAddressCity('');
                zipCode.value = [];
                useAuthStore.updateAddressZipcode('');
                city.value = [];
            }
          });

          watch(cityVal, async (newVal) => {
            if(newVal) {
                if (stateVal.value != null) {
                    getZipCode(stateVal.value, newVal);
                    useAuthStore.updateAddressZipcode('');
               }
            } else {
                if (stateVal.value != null) {
                    useAuthStore.updateAddressZipcode('');
                    zipCode.value = [];
               }
            }

          });

          onMounted(async () => {
               import('vue-tel-input/vue-tel-input.css');
               await useUtilitiesStore.getState();

               if (profileFormModel.value.address.province_id) {
                    await getCity(profileFormModel.value.address.province_id);
                    await getZipCode(
                         profileFormModel.value.address.province_id,
                         profileFormModel.value.address.city_id
                    );
               }


          });

          return {
               // form,
               eighteenYearsAgo,
               resendVerification,
               profile_form,
               profileFormModel,
               set_phone_form,
               state,
               city,
               zipCode,
               NumbersOnly,
               onCountryChanged,
               onValidatePhone,
               isValidPhoneNumber,
               handlePhoneNumberInput,
               set_phone_area,
               clearFormError,
          };
     },
};
</script>
