<template>
    <transition enter-from-class="scale-0" enter-active-class="ease-in transition-medium" enter-to-class="scale-1"
        leave-from-class="scale-1" leave-active-class="ease-out transition-medium" leave-to-class="scale-0">
        <div @click="logOut" v-if="isOpenSessionExpired" id="authentication-modal" tabindex="-1" aria-hidden="true"
            class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight  flex justify-center items-center">
            <div class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto text-center">
                <!-- Modal content -->
                <div class="relative bg-shade-1 rounded-xl shadow-xl">
                    <button @click="logOut()" type="button"
                        class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                        data-modal-hide="authentication-modal">
                        <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
                        <span class="sr-only">Close modal</span>
                    </button>
                    <div
                        class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight">
                        <div class="pb-4 w-full text-center">
                            <h3 class="text-base xs:text-lg font-bold text-gray-900">
                                Session expired
                            </h3>
                        </div>
                        <div class="pb-2 pt-6 w-full font-normal">
                            <p class="md:px-6 lg:px-10">Someone login your account on another device</p>
                            <div class="pt-2 xs:pt-10 text-center w-full font-helveticaLight">
                                <div class="px-2 flex w-full gap-4">
                                    <!-- <button @click="logOut()"
                                        class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold">
                                        Log out
                                    </button> -->
                                    <button @click="logOut()"
                                        class="w-full bg-secondary-3 text-primary-1 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold">
                                        Log out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <div @click="logOut" v-if="isOpenSessionExpired" class="fixed inset-0 z-60" style="background-color: rgba(0, 0, 0, 0.3)"></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "store/auth.js";
import { baseUrl } from "config/services.js";

export default {
    setup(props, { emit }) {
        const { money } = useFormatter();
        const router = useRouter();

        const isOpenSessionExpired = ref(false);
        const useUtilitiesStore = utilitiesStore();
        const useAuthStore = authStore();

        const toggleSessionExpired = () => {
            isOpenSessionExpired.value = !isOpenSessionExpired.value;
        };

        const closeModal = () => {
            isOpenSessionExpired.value = false;
            useUtilitiesStore.enableScroll();
        };

        const logOut = async () => {
            closeModal();
            useAuthStore.logout().then(() => {
                router.push({ path: "/signin" });
            });
        };

        onMounted(async () => {
            eventBus.on("open:session_expired", () => {
                useUtilitiesStore.disableScroll();
                toggleSessionExpired();
            });
        });

        onUnmounted(() => {
            eventBus.off("open:session_expired");
        });

        return {
            isOpenSessionExpired,
            closeModal,
            logOut,
            baseUrl
        };
    },
};
</script>
